/* KeyboardTester.css */
#keyboard-tester {
  width: 50%;
}
#keyboard-modal {
  height: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--orange) !important;
}

#keyboard-header-container {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100% !important;
  height: 100%;
  position: relative;
 
  h2 {
     margin: 0;
     display: flex;
     align-items: center;
     @media (max-width: 400px) {
        font-size: 18px;
     }
  }
  .modal-close-container {
     position: relative;

     .FaTimes {
        font-size: 20px;
        cursor: pointer;
        transition: var(--transition-all);
        position: absolute;
        top: 4px;
        right: 4px;
     }
     .FaTimes:hover {
        color: var(--orange) !important;
     }
  }

}

#keyboard-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  background-color: var(--orange);
  border-radius: var(--border-radius);
  height: 52px;

  h3 {
    font-size: var(--header) !important;
    font-weight: 600;
  }
}

.keyboard {
   display: flex;
   flex-direction: column;
   align-items: center;
 }
 
 .keyboard-row {
   display: flex;
   margin-bottom: 10px;
 }
 
 .key {
    min-width: 50px;
    width: fit-content;
    padding: 8px 16px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border-radius: 5px;
    border: 1px solid var(--black);
    font-size: 14px;
    cursor: default;
    box-shadow: 0 4px #666;
    transition: background-color 0.3s, box-shadow 0.3s;
    color: black;
  }
 
 .key.active {
   background-color: var(--orange);
   box-shadow: 0 4px var(--orange);
   color: var(--white);
 }

 .key.persistent {
  background-color: #fbc0a3;  /* Persistent highlight color (green) */
  color: black;
  box-shadow: 0 4px #2c2c2c;
}

 
 .key.large {
   width: 100px;
 }
 
 .key.space {
   width: 300px;
 }
 
 .reload-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 16px;
 }
 .TbReload {
  font-size: 25px;
  cursor: pointer;
  transition: var(--transition-all);
}
 .TbReload:hover {
  color: var(--orange) !important;
}