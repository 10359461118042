#main-body {
   margin: 8px 0;
   width: 100%;
   max-width: 1400px;
   display: flex;
   background-color: var(--darker-black);
   border-radius: var(--border-radius);
   border: 2px solid var(--dark-black);
   animation: var(--pulseBorderOrange);
   box-shadow: var(--box-shadow);
   padding:16px;
   flex-direction: column;

   @media (max-width: 400px) {
      width: 100%;
      padding: 16px 0;
   }

   .sub-header {
      margin: 8px 0;
      padding: 8px 0 8px 0;
      display: flex;
      flex-direction: row;
      gap: 8px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border-top: 2px solid var(--dark-black);
      border-bottom: 2px solid var(--dark-black);
   }
   
   #latest-video-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h3 {
         font-size: var(--header);
         font-weight: var(--super-bold);
         margin: 0;
      }
   }

   #product-wallpaper-container {
      display: flex;
      flex-direction: column;

      #product-cont {
         flex: 1;
         padding: 8px 0;
         border-bottom: 2px solid var(--dark-black);
      }

      #wallpaper-cont {
         flex: 1;
         padding: 8px 0 0 0;
      }

      @media (max-width: 720px) {
         flex-direction: column;
         #product-cont {
            flex: 1;
            margin:  0;
            width: 100%;
         }
   
         #wallpaper-cont {
            flex: 1;
            margin: 0;
            width: 100%;
         }
      }
   }

   .coffee-and-keyboard {
      width: 100%;
      display: flex;
      gap: 8px;
      padding: 16px 0;
   }
}

.open-modal-text {
   color: var(--white);
   transition: var(--transition-all);
   cursor: pointer;
   border: 2px solid transparent;
   padding: 0 8px;
   border-radius: var(--border-radius);
   height: 30px !important;
   font-weight: 600;
   font-size: var(--paragraph);
   background-color: var(--black);
   display: flex;
    align-items: center;
    gap: 4px;
}
.open-modal-text:hover {
   color: var(--white);
   border: 2px solid transparent;
   background-color: var(--orange);
}

#discord, #deskmat {
   cursor: pointer;
   text-decoration: none;
   color: var(--white);
   transition: var(--transition-all);
   h3 {
      font-size: var(--header);
      height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: var(--super-bold);
   }
}

.discord {
   pointer-events: none;
   background-color: #7289da;
   border-radius: var(--border-radius);
}




