.deskmat-card {
   display: flex;
   flex-direction: column;
   overflow: hidden;
   margin-top: 8px;
   width: 100%;
   position: relative;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;


   font-size: var(--sub-header);
   font-weight: 800;
   text-decoration: none;

   color: var(--white);
   background-color: var(--darker-black);

   border-radius: var(--border-radius);

   transition: var(--transition-all);
   @media (max-width: 500px) {
      width: 100%;
      padding: 0;
      min-width: 0px;
   }

   img {
      aspect-ratio: 5/3;
      width: 100%;
      object-fit: cover;
   }
   p {
      margin: 0;
   }
}

.deskmat-link {
   width: 100%;
   text-decoration: none;
   padding: 8px;
   cursor: pointer;
   h4 {
      transition: var(--transition-all);
      font-size: var(--sub-header);
      font-weight: 600 !important;
      margin: 0;
      white-space: nowrap;
      color: var(--white);
   }
   p {
      transition: var(--transition-all);
      margin: 0;
      font-size: var(--paragraph);
      overflow: hidden;
      height: 18px;
      color: var(--darker-white);
   }
}

#deskmat-icons {
   position: absolute;
   top: 0;
   right: 8px;
   transition: var(--transition-all);
   display: flex;
}

.deskmat-icon {
   cursor: pointer;
   padding: 0 4px;
   backdrop-filter: blur(5px);
   height: 30px;
   .FaVideo {
      font-size: var(--icon);
      color: var(--darker-white);
   }
}

.deskmat-icon:hover, .deskmat-icon:hover .FaVideo {
   color: var(--orange);
}


#preview-video-iframe {
   aspect-ratio: 16/9;
   border-radius: var(--border-radius);
   width: 1400px;

   @media (max-width: 1400px) {
      width: 850px;
   }

   @media (max-width: 820px) {
      width: 100%;
   }
}

#preview-modal {
   height: fit-content;
   padding: 0 !important;
}

.deskmat-link-border {
   border: 2px solid var(--black);
}

.deskmat-link-border:hover {
   border: 2px solid var(--orange);
}