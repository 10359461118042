#buy-me-a-coffee {
   width: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 8px;
   cursor: pointer;
   background-color: var(--orange);
   border-radius: var(--border-radius);
   height: 52px;

   div h3 {
      flex-grow: 1;
      font-size: var(--header) !important;
      font-weight: 600;
   }
}